import { request, noTimeOutReq } from "@/utils/request.js";

// 删除【配备标准数据】
export function deleteEquipStandardInfoApi(equipStandardId) {
  return request({
    url: `/sys/experiment/equip/standard/delete`,
    method: "post",
    params: {
      equipStandardId,
    },
  });
}

// 获取【配备标准数据】详情
export function getEquipStandardDetailApi(equipStandardId) {
  return request({
    url: `/sys/experiment/equip/standard/get`,
    method: "post",
    params: {
      equipStandardId,
    },
  });
}

// 分页展示所有【配备标准表信息】
export function getEquipStandardListApi(pageIndex, pageSize, data) {
  return request({
    url: `/sys/experiment/equip/standard/page/${pageIndex}/${pageSize}/list`,
    method: "post",
    data,
  });
}

// 修改【配备标准数据】
export function updateEquipStandardInfoApi(data) {
  return request({
    url: `/sys/experiment/equip/standard/update`,
    method: "post",
    data,
  });
}

// 新增【配备标准数据 save-保存 stat-暂存】
export function addEquipStandardInfoApi(saveMethod, data) {
  return request({
    url: `/sys/experiment/equip/standard/${saveMethod}`,
    method: "post",
    data,
  });
}

// 获取【配备标准列表】
export function getAllEquipStandardListApi(data) {
  return request({
    url: `/sys/experiment/equip/standard/list`,
    method: "post",
    data
  });
}
