<template>
  <div class="equip-management-container">
    <!-- 添加标准按钮 -->
    <el-row>
      <el-button
        icon="el-icon-circle-plus-outline"
        type="primary"
        v-throttle
        @click="handleAddEquipStandard"
        >添加配备标准</el-button
      >
    </el-row>
    <!-- 查询表单区域 -->
    <el-row style="margin-top: 20px">
      <el-form inline :model="equipStandardFormCondition">
        <el-form-item
          class="formItemBoxStyle"
          prop="equipStandardCode"
          label="编号:"
        >
          <el-input
            v-model="equipStandardFormCondition.equipStandardCode"
            placeholder="请输入"
            @blur="
              () => {
                handleQueryEquipStandardBtnClick();
              }
            "
          />
        </el-form-item>
        <el-form-item
          class="formItemBoxStyle"
          prop="equipStandardName"
          label="标准名称:"
        >
          <el-input
            v-model="equipStandardFormCondition.equipStandardName"
            placeholder="请输入"
            @blur="
              () => {
                handleQueryEquipStandardBtnClick();
              }
            "
          />
        </el-form-item>
        <el-form-item
          class="formItemBoxStyle"
          prop="subjectDicId"
          label="所属科目:"
        >
          <el-select
            v-model="equipStandardFormCondition.subjectDicId"
            @change="handleQueryEquipStandardBtnClick"
          >
            <el-option label="全部科目" value=""></el-option>
            <el-option
              v-for="item in subjectList"
              :key="item.sysDicId"
              :label="item.sysDicName"
              :value="item.sysDicId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          class="formItemBoxStyle"
          prop="periodDicId"
          label="所属学段:"
        >
          <el-select
            v-model="equipStandardFormCondition.periodDicId"
            @change="handleQueryEquipStandardBtnClick"
          >
            <el-option label="全部学段" value=""> </el-option>
            <el-option
              v-for="item in periodList"
              :key="item.sysDicId"
              :label="item.sysDicName"
              :value="item.sysDicId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="formItemBoxStyle" prop="enabled" label="状态:">
          <el-select
            v-model="equipStandardFormCondition.enabled"
            @change="handleQueryEquipStandardBtnClick"
          >
            <el-option label="全部" value=""></el-option>
            <el-option label="启用" :value="1"></el-option>
            <el-option label="停用" :value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button @click="handleResetEquipStandardBtnClick">重置</el-button>
          <el-button type="primary" @click="handleQueryEquipStandardBtnClick"
            >查询</el-button
          >
        </el-form-item>
      </el-form>
    </el-row>
    <!-- 表格区域 -->
    <el-row>
      <el-table
        :data="equipStandardTableData"
        :header-cell-style="{
          'text-align': 'center',
          backgroundColor: '#dfe6ec',
        }"
        :cell-style="{ 'text-align': 'center' }"
      >
        <el-table-column
          prop="equipStandardCode"
          label="编号"
          center
          width="120px"
        ></el-table-column>
        <el-table-column prop="equipStandardName" label="配备标准名称" center>
          <template slot-scope="scoped">
            <div
              @click="handleEquipStandardNameClick(scoped.row)"
              style="color: #00f; text-decoration: underline; cursor: pointer"
            >
              {{ scoped.row.equipStandardName }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="subjectDicName"
          label="所属科目"
          center
        ></el-table-column>
        <el-table-column
          prop="periodDicName"
          label="所属学段"
          center
        ></el-table-column>
        <el-table-column
          prop="createByName"
          label="创建者"
          center
        ></el-table-column>
        <el-table-column prop="gmtCreate" label="创建时间" center>
          <template slot-scope="scoped">
            <span>{{ handleTimeFormate(scoped.row.gmtCreate) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="enabled" label="状态" center width="120px">
          <template slot-scope="scoped">
            {{ scoped.row.enabled === 1 ? "启用" : "停用" }}
          </template>
        </el-table-column>
        <el-table-column label="操作" center>
          <template slot-scope="scoped">
            <!-- 停用状态 -->
            <div
              v-if="scoped.row.enabled === 0"
              style="display: flex; margin-left: 55px"
            >
              <div
                style="
                  margin-right: 10px;
                  cursor: pointer;
                  color: green;
                  text-decoration: underline;
                "
                @click="handleEditEquipStandardBtnClick(scoped.row, 0)"
              >
                编辑
              </div>
              <div
                style="
                  margin-right: 10px;
                  cursor: pointer;
                  color: rgb(64, 158, 255);
                  text-decoration: underline;
                "
                @click="
                  handleOpenChangeEquipStandardDialog(
                    '启用',
                    scoped.row.equipStandardId
                  )
                "
              >
                启用
              </div>
              <div
                @click="
                  handleOpenChangeEquipStandardDialog(
                    '删除',
                    scoped.row.equipStandardId
                  )
                "
                style="cursor: pointer; color: red; text-decoration: underline"
              >
                删除
              </div>
            </div>
            <!-- 启动状态 -->
            <div v-else style="display: flex; margin-left: 75px">
              <!-- <div
                style="
                  margin-right: 10px;
                  cursor: pointer;
                  color: green;
                  text-decoration: underline;
                "
                @click="handleEditEquipStandardBtnClick(scoped.row)"
              >
                编辑
              </div> -->
              <div
                style="
                  margin-left: 18px;
                  cursor: pointer;
                  color: red;
                  text-decoration: underline;
                "
                @click="
                  handleOpenChangeEquipStandardDialog(
                    '停用',
                    scoped.row.equipStandardId
                  )
                "
              >
                停用
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <!-- 分页组件 -->
    <el-row style="margin-top: 20px">
      <el-pagination
        @size-change="handleSizeChangeEquipStandardPageInfo"
        @current-change="handleCurrentChangeEquipStandardPageInfo"
        :current-page="equipStandardPageInfo.pageIndex"
        :page-sizes="[10, 20, 50, 100, 150, 200]"
        :page-size="equipStandardPageInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="equipStandardPageInfo.pageTotal"
      ></el-pagination>
    </el-row>
    <!-- 新增或编辑配备标准弹框 -->
    <el-dialog
      :title="
        addOrEditEquipStandard === 1
          ? '新增实验仪器耗材配备标准'
          : '编辑实验仪器耗材配备标准'
      "
      :visible.sync="addOrEditEquipStandardDialogVisible"
      top="3%"
      width="1200px"
    >
      <div style="width: 650px; margin: 0 auto">
        <el-form
          class="equipStandardFormData"
          :model="equipStandardFormData"
          ref="equipStandardFormData"
          :rules="rules"
        >
          <el-form-item prop="standardName">
            <span class="redStar">配备标准名称：</span>
            <el-input
              v-model.trim="equipStandardFormData.standardName"
              placeholder="请输入"
              maxlength="50"
              style="width: 400px"
              onkeyup="this.value=this.value.replace(/\s/g,'')"
              :disabled="isCheckingEquipStandard"
            ></el-input>
          </el-form-item>
          <el-form-item prop="standardCode">
            <span class="redStar" style="margin-left: 56px">编号：</span>
            <el-input
              v-model.trim="equipStandardFormData.standardCode"
              placeholder="请输入"
              maxlength="6"
              style="width: 400px"
              onkeyup="this.value=this.value.replace(/\s/g,'')"
              :disabled="
                isCheckingEquipStandard || addOrEditEquipStandard === 0
              "
            ></el-input>
          </el-form-item>
          <el-form-item prop="subject">
            <span class="redStar" style="margin-left: 28px">所属科目：</span>
            <el-select
              v-model="equipStandardFormData.subject"
              placeholder="请选择"
              style="width: 400px"
              :disabled="
                isCheckingEquipStandard || addOrEditEquipStandard === 0
              "
            >
              <el-option
                v-for="item in subjectList"
                :key="item.sysDicId"
                :label="item.sysDicName"
                :value="item.sysDicId"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="period">
            <span class="redStar" style="margin-left: 28px">所属学段：</span>
            <el-select
              v-model="equipStandardFormData.period"
              placeholder="请选择"
              style="width: 400px"
              :disabled="
                isCheckingEquipStandard || addOrEditEquipStandard === 0
              "
            >
              <el-option
                v-for="item in periodList"
                :key="item.sysDicId"
                :label="item.sysDicName"
                :value="item.sysDicId"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="schoolList">
            <div style="display: flex; width: 600px">
              <div class="redStar" style="margin-left: 28px">执行学校：</div>
              <el-input
                style="width: 400px"
                placeholder="请点击 [添加] 按钮进行添加"
                class="addSchoolInput"
                type="textarea"
                disabled
                rows="3"
                resize="none"
                v-model="equipStandardFormData.schoolList"
              />
              <div
                v-if="!isCheckingEquipStandard"
                style="
                  color: #00f;
                  text-decoration: underline;
                  cursor: pointer;
                  margin-left: 10px;
                "
                @click="handleAddSchoolBtnClick"
              >
                +添加
              </div>
            </div>
          </el-form-item>
          <div
            style="
              height: 1px;
              width: 800px;
              background-color: #ccc;
              margin-left: -100px;
            "
          ></div>
        </el-form>
      </div>
      <el-row style="margin-top: 20px">
        <div
          style="
            margin: 0 auto;
            width: 700px;
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;
          "
        >
          <div class="redStar">仪器耗材配备标准：</div>
          <div>
            <span
              v-if="!isCheckingEquipStandard"
              style="
                color: #00f;
                text-decoration: underline;
                cursor: pointer;
                margin-right: 20px;
              "
              >+ 导入</span
            >
            <span
              v-if="!isCheckingEquipStandard"
              style="color: #00f; text-decoration: underline; cursor: pointer"
              @click="handleAddMaterialBtnClick"
              >+ 添加仪器耗材</span
            >
          </div>
        </div>
        <el-table
          :header-cell-style="{
            'text-align': 'center',
            backgroundColor: '#dfe6ec',
          }"
          :cell-style="{ 'text-align': 'center' }"
          :data="addedMaterialDataList"
          border
        >
          <el-table-column
            label="编号"
            prop="internationCode"
            width="80"
          ></el-table-column>
          <el-table-column
            label="名称"
            prop="materialName"
            width="100"
          ></el-table-column>
          <el-table-column
            label="规格型号功能"
            prop="materialSpecifications"
          ></el-table-column>
          <el-table-column
            width="50"
            label="单位"
            prop="materialUnit"
          ></el-table-column>
          <el-table-column
            label="参考单价"
            prop="materialUnitPrice"
            width="80"
          ></el-table-column>
          <el-table-column
            label="标准数量"
            prop="materialStandardNumber"
            width="250"
            v-if="addOrEditEquipStandard !== 2"
          >
            <template slot-scope="scope">
              <el-input-number
                v-model="scope.row.materialMinStandardNum"
                controls-position="right"
                style="width: 100px"
                :min="1"
                :max="scope.row.materialMaxStandardNum - 1"
              ></el-input-number>
              <span> ~ </span>
              <el-input-number
                v-model="scope.row.materialMaxStandardNum"
                controls-position="right"
                style="width: 100px"
                :min="scope.row.materialMinStandardNum + 1"
                :max="1000"
              ></el-input-number>
            </template>
          </el-table-column>
          <el-table-column
            label="标准数量"
            prop="materialStandardNumber"
            width="250"
            v-else
          >
            <template slot-scope="scoped">
              {{ scoped.row.materialMinStandardNum }} ~
              {{ scoped.row.materialMaxStandardNum }}
            </template>
          </el-table-column>
          <el-table-column
            label="是否危化品"
            prop="materialHasDanger"
            width="100"
          >
            <template slot-scope="scope">
              {{ scope.row.materialHasDanger ? "是" : "否" }}
            </template>
          </el-table-column>
          <el-table-column
            label="是否消耗品"
            prop="materialHasConsumables"
            width="100"
          >
            <template slot-scope="scope">
              {{ scope.row.materialHasConsumables ? "是" : "否" }}
            </template>
          </el-table-column>
          <el-table-column label="配备要求" width="200">
            <template slot-scope="scoped">
              <el-radio-group
                v-model="scoped.row.equipRequire"
                :disabled="isCheckingEquipStandard"
              >
                <el-radio :label="2">选配</el-radio>
                <el-radio :label="1">基本</el-radio>
              </el-radio-group>
            </template>
          </el-table-column>
          <el-table-column width="80">
            <template slot-scope="scoped">
              <i
                v-if="!isCheckingEquipStandard"
                class="el-icon-delete"
                style="
                  color: red;
                  font-size: 22px;
                  cursor: pointer;
                  font-weight: 500;
                "
                @click="handleDeleteAddedMaterialBtnClick(scoped.row)"
              ></i>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
      <div
        v-if="!isCheckingEquipStandard"
        style="margin-top: 20px; text-align: right"
      >
        <el-button
          type="primary"
          v-throttle
          @click="handleSaveAndPublishStandardBtnClick('save')"
          >保存并发布</el-button
        >
        <el-button
          v-throttle
          @click="handleSaveAndPublishStandardBtnClick('stat')"
          >暂存</el-button
        >
        <el-button v-throttle @click="handleCancelStandardBtnClick"
          >取消</el-button
        >
      </div>
    </el-dialog>
    <!-- 添加学校或添加仪器耗材弹框 -->
    <el-dialog
      :visible.sync="addSchoolDialogVisible"
      :title="addSchoolOrMaterial === 1 ? '添加执行学校' : '添加仪器耗材'"
      width="800px"
    >
      <template v-if="addSchoolOrMaterial === 1">
        <div style="display: flex; justify-content: space-between">
          <div class="tag-title">已选择</div>
          <span
            @click="handleRemoveAllAddedSchoolBtnClick"
            style="
              color: red;
              text-decoration: underline;
              cursor: pointer;
              margin-right: 10px;
            "
            >清空所有</span
          >
        </div>

        <div class="tag-content">
          <div
            v-for="item in addSchoolList"
            :key="item.sysOrgSchoolId"
            class="tag-content-item"
          >
            {{ item.name }}
            <div class="tag-item-x" @click="handleRemoveSelectSchoolBtn(item)">
              X
            </div>
          </div>
        </div>
        <div class="tag-title">学校列表</div>
        <el-form inline :model="schoolListQueryCondition">
          <el-form-item prop="areaId" label="区县：" class="formItemBoxStyle">
            <el-select
              v-model="schoolListQueryCondition.sysOrgAreaId"
              style="width: 120px"
              @change="fetchSchoolInfo"
            >
              <el-option label="全部" :value="0"></el-option>
              <el-option
                v-for="item in sysOrgAreaList"
                :key="item.orgId"
                :label="item.orgName"
                :value="item.orgId"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="name">
            <el-input
              v-model.trim="schoolListQueryCondition.name"
              placeholder="请输入学校名称查询"
              style="width: 180px"
              @input="fetchSchoolInfo"
            />
          </el-form-item>
          <el-form-item prop="name">
            <el-button v-throttle @click="handleResetSchoolData"
              >重置</el-button
            >
            <el-button type="primary" v-throttle @click="fetchSchoolInfo"
              >查询</el-button
            >
            <!-- <el-button
              type="primary"
              style="margin-left: 90px"
              v-throttle
              @click="handleBatchAddBtnClick"
              >批量添加</el-button
            > -->
          </el-form-item>
        </el-form>
        <el-table
          border
          ref="schoolTableDataList"
          :data="schoolTableDataList"
          :header-cell-style="{
            'text-align': 'center',
            backgroundColor: '#dfe6ec',
          }"
          :cell-style="{ 'text-align': 'center' }"
          @select="handleSelectOneSchool"
          @select-all="handleSelectAllSchool"
          @row-click="schoollRowClick"
          height="300px"
        >
          <el-table-column label="区县" prop="sysOrgAreaName"></el-table-column>
          <el-table-column label="代码" prop="code"></el-table-column>
          <el-table-column label="名称" prop="name"></el-table-column>
          <!-- <el-table-column label="操作">
            <template slot-scope="scoped">
              <div
                v-if="!handleSchoolStatusCheck(scoped.row)"
                style="color: #00f; text-decoration: underline; cursor: pointer"
                @click="handleTableAddSchoolBtnClick(scoped.row)"
              >
                添加
              </div>
              <div v-else style="color: #ccc">已添加</div>
            </template>
          </el-table-column> -->
          <el-table-column type="selection" width="55"> </el-table-column>
        </el-table>

        <div style="margin-top: 20px; text-align: right">
          <el-button v-throttle @click="handleCancelAddSchoolBtnClick"
            >取消</el-button
          >
          <el-button
            type="primary"
            v-throttle
            @click="handleSaveAddSchoolBtnClick"
            >保存</el-button
          >
        </div>
      </template>
      <template v-else>
        <!-- 仪器耗材添加弹框 -->
        <div style="display: flex; justify-content: space-between">
          <div class="tag-title">已选择</div>
          <span
            @click="handleRemoveAllAddedMaterialBtnClick"
            style="
              color: red;
              text-decoration: underline;
              cursor: pointer;
              margin-right: 10px;
            "
            >清空所有</span
          >
        </div>
        <div class="tag-content">
          <div
            v-for="item in addMaterialList"
            :key="item.materialId"
            class="tag-content-item"
          >
            {{ item.materialName }}
            <div
              class="tag-item-x"
              @click="handleRemoveSelectMaterialBtn(item)"
            >
              X
            </div>
          </div>
        </div>
        <div class="tag-title">仪器耗材库</div>
        <el-row>
          <el-input
            v-model.trim="materialStandardInput"
            placeholder="请输入编号或名称进行查询"
            style="width: 300px; margin-right: 20px"
            @input="handleQueryMaterialTableDataBtnClick"
          />
          <el-button @click="handleResetMaterialTableDataBtnClick"
            >重置</el-button
          >
          <el-button
            type="primary"
            @click="handleQueryMaterialTableDataBtnClick"
            >查询</el-button
          >
        </el-row>
        <el-row style="margin-top: 20px">
          <el-table
            border
            :data="materialTableDataList"
            ref="materialTableDataList"
            :header-cell-style="{
              'text-align': 'center',
              backgroundColor: '#dfe6ec',
            }"
            :cell-style="{ 'text-align': 'center' }"
            @select="handleSelectOneMaterial"
            @select-all="handleSelectAllMaterial"
            @row-click="materialRowClick"
          >
            <el-table-column
              prop="internationCode"
              label="国标编号"
              center
              width="80px"
            ></el-table-column>
            <el-table-column prop="materialName" label="名称" center>
            </el-table-column>
            <el-table-column
              prop="materialSpecifications"
              label="规格型号功能"
              center
              width="150px"
            ></el-table-column>
            <el-table-column
              prop="materialUnit"
              label="单位"
              center
              width="60px"
            ></el-table-column>
            <el-table-column label="标准数量" center>
              <template slot-scope="scoped">
                {{ scoped.row.materialMinStandardNum }} ~
                {{ scoped.row.materialMaxStandardNum }}
              </template>
            </el-table-column>
            <el-table-column prop="materialHasDanger" label="是否危化品" center>
              <template slot-scope="scoped">
                {{ scoped.row.materialHasDanger ? "是" : "否" }}
              </template>
            </el-table-column>
            <el-table-column
              prop="materialHasConsumables"
              label="是否消耗品"
              center
            >
              <template slot-scope="scoped">
                {{ scoped.row.materialHasConsumables ? "是" : "否" }}
              </template>
            </el-table-column>
            <el-table-column type="selection" width="55"></el-table-column>
          </el-table>
        </el-row>
        <el-row style="margin-top: 20px">
          <el-pagination
            @size-change="handleSizeChangeMaterialTableDataMsgPage"
            @current-change="handleCurrentChangeMaterialTableDataMsgPage"
            :current-page="materialTableDataMsgPage.pageIndex"
            :page-sizes="[5, 10, 20, 50, 100, 150]"
            :page-size="materialTableDataMsgPage.pageSize"
            layout="->,total,  prev, pager, next,sizes, jumper"
            :total="materialTableDataMsgPage.pageTotal"
          ></el-pagination>
        </el-row>
        <div style="margin-top: 20px; text-align: right">
          <el-button @click="handleCancelAddMaterialBtnClick">取消</el-button>
          <el-button type="primary" @click="handleSaveAddMaterialBtnClick"
            >保存</el-button
          >
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { getDicFormInfoApi } from "@/api/standard/dictionaryForm.js";
import { getMaterialListApi } from "@/api/standard/standardDeviceLib.js";
import {
  deleteEquipStandardInfoApi,
  getEquipStandardDetailApi,
  getEquipStandardListApi,
  updateEquipStandardInfoApi,
  addEquipStandardInfoApi,
} from "@/api/standard/equipManagement.js";
import { formatDate } from "@/utils/util.js";
import { getParamSchoolApi } from "@/api/mechanism/examSchool.js";
import { getAreaSite } from "@/api/mechanism/examArea.js";
export default {
  name: "equipManagement",
  data() {
    const checkStandardCode = (rule, value, callback) => {
      let reg = /[^a-z0-9A-Z]/g;
      if (reg.test(this.equipStandardFormData.standardCode)) {
        callback(new Error("编号只能是数字或字母"));
      } else {
        callback();
      }
    };
    return {
      addOrEditEquipStandard: 1, // 1 表示新增 0 表示编辑
      addSchoolOrMaterial: 1, // 1 表示新增执行学校，0 表示新增仪器耗材
      equipStandardFormCondition: {
        equipStandardCode: "",
        equipStandardName: "",
        subjectDicId: "",
        periodDicId: "",
        enabled: "",
      },
      subjectList: [],
      periodList: [],
      equipStandardTableData: [],
      equipStandardPageInfo: {
        pageIndex: 1,
        pageSize: 10,
        pageTotal: 0,
      },
      addOrEditEquipStandardDialogVisible: false,
      equipStandardFormData: {
        standardName: "",
        standardCode: "",
        subject: "",
        period: "",
        schoolList: "",
      },
      rules: {
        standardName: [
          { required: true, message: "请输入配备标准名称", trigger: "blur" },
        ],
        standardCode: [
          { required: true, message: "请输入编号", trigger: "blur" },
          { validator: checkStandardCode, trigger: "blur" },
        ],
      },
      addedMaterialDataList: [],
      addedMaterialDataListBeforeEdit: [],
      addSchoolDialogVisible: false,
      addSchoolList: [],
      tempAddSchoolList: [], // 保存之前的数据，用于取消弹框后恢复数据
      addSchoolListBeforEdit: [], // 保存之前的数据，用于比较后发送更新请求
      schoolDataRelationList: [], // 用于暂存学校数据
      isSavingSchooldList: false,
      schoolListQueryCondition: {
        sysOrgAreaId: 0,
        name: "",
      },
      schoolTableDataList: [],
      schoolSelectionList: [],
      addMaterialList: [],
      tempAddMaterialList: [],
      isSavingMaterialList: false,
      materialStandardInput: "",
      materialTableDataList: [],
      materialTableDataMsgPage: {
        pageIndex: 1,
        pageSize: 5,
        pageTotal: 0,
      },
      equipStandardId: "",
      isCheckingEquipStandard: false,

      // sysOrgAreaId: "",
      sysOrgAreaList: [],
    };
  },
  watch: {
    addSchoolDialogVisible: function (val) {
      if (this.addSchoolOrMaterial === 1) {
        if (!val && !this.isSavingSchooldList) {
          this.handleCancelAddSchoolBtnClick();
        } else {
          this.isSavingSchooldList = false;
        }
      } else {
        if (!val && !this.isSavingMaterialList) {
          this.handleCancelAddMaterialBtnClick();
        } else {
          this.isSavingMaterialList = false;
        }
      }
    },
    addOrEditEquipStandardDialogVisible: function (val) {
      if (!val) {
        this.isCheckingEquipStandard = false;
      }
    },
  },
  created() {
    this.fetchEquipStandardListInfo();
    this.fetchSubjectInfo();
    this.fetchPeriodInfo();
    this.fetchSchoolInfo();
    this.fetchAreaSelectInfo();
  },
  methods: {
    // 获取科目以及学段信息
    fetchSubjectInfo() {
      getDicFormInfoApi(4).then((res) => {
        if (res.success) {
          this.subjectList = res.data;
        } else {
          this.$message.error(res.msg);
          return;
        }
      });
    },
    // 获取科目以及学段信息
    fetchPeriodInfo() {
      getDicFormInfoApi(5).then((res) => {
        if (res.success) {
          this.periodList = res.data;
        } else {
          this.$message.error(res.msg);
          return;
        }
      });
    },
    // 获取学校信息
    fetchSchoolInfo() {
      getParamSchoolApi(this.schoolListQueryCondition).then((res) => {
        if (res.success) {
          this.schoolTableDataList = res.data;
        } else {
          this.$message.error(res.msg);
          return;
        }
      });
    },
    // 获取仪器耗材列表数据
    fetchMaterialListInfo(data = {}) {
      getMaterialListApi(
        this.materialTableDataMsgPage.pageIndex,
        this.materialTableDataMsgPage.pageSize,
        data
      ).then((res) => {
        // console.log(res, "res");
        if (res.success) {
          this.materialTableDataList = res.data;
          if (
            this.addMaterialList.length > 0 &&
            this.materialTableDataList.length > 0
          ) {
            this.materialTableDataList.forEach((it) => {
              this.addMaterialList.forEach((it2) => {
                if (it.materialId == it2.materialId) {
                  this.$nextTick(() => {
                    this.$refs.materialTableDataList.toggleRowSelection(
                      it,
                      true
                    );
                    it.isSelected = true;
                  });
                }
              });
            });
          }
          this.materialTableDataMsgPage.pageTotal = res.total;
        } else {
          this.$message.error(res.msg);
          return;
        }
      });
    },
    //顶部地区下拉框获取数据
    fetchAreaSelectInfo() {
      getAreaSite().then((res) => {
        let { code, data, msg } = res;
        if (code == 200) {
          // data.childrenOrg.unshift({ orgId: 0, orgName: '全部' })
          this.sysOrgAreaList = data.childrenOrg;
        } else {
          console.log("地区筛选下拉框数据获取失败");
        }
      });
    },
    // 获取配备标准数据
    fetchEquipStandardListInfo(data = this.equipStandardFormCondition) {
      getEquipStandardListApi(
        this.equipStandardPageInfo.pageIndex,
        this.equipStandardPageInfo.pageSize,
        data
      ).then((res) => {
        if (res.success) {
          this.equipStandardTableData = res.data;
          this.equipStandardPageInfo.pageTotal = res.total;
        } else {
          this.$message.error(res.msg);
          return;
        }
      });
    },
    // 添加学校按钮
    handleAddSchoolBtnClick() {
      this.addSchoolOrMaterial = 1;
      // 保存数据
      this.tempAddSchoolList = [];
      for (let i = 0; i < this.addSchoolList.length; i++) {
        let obj = {};
        for (const key in this.addSchoolList[i]) {
          obj[key] = this.addSchoolList[i][key];
        }
        this.tempAddSchoolList.push(obj);
      }
      this.addSchoolDialogVisible = true;
    },
    // 删除已添加学校按钮
    handleRemoveSelectSchoolBtn(item) {
      // this.addSchoolList.splice(this.addSchoolList.indexOf(item), 1);
      this.addSchoolList.forEach((it, i) => {
        if (item.sysOrgSchoolId == it.sysOrgSchoolId) {
          this.addSchoolList.splice(i, 1);
        }
      });
      this.$nextTick(() => {
        this.schoolTableDataList.forEach((it) => {
          if (it.sysOrgSchoolId == item.sysOrgSchoolId) {
            this.$refs.schoolTableDataList.toggleRowSelection(it, false);
            it.isSelected = false;
          }
        });
      });
    },
    // 删除所有已添加学校按钮
    handleRemoveAllAddedSchoolBtnClick() {
      this.addSchoolList = [];
      this.schoolTableDataList.forEach((it) => {
        it.isSelected = false;
        this.$refs.schoolTableDataList.toggleRowSelection(it, false);
      });
    },
    // 表格中添加学校按钮
    handleTableAddSchoolBtnClick(row) {
      // console.log(row);
      this.addSchoolList.push(row);
    },
    // 取消添加执行学校
    handleCancelAddSchoolBtnClick() {
      this.addSchoolList = this.tempAddSchoolList;
      this.addSchoolDialogVisible = false;
    },
    // 保存添加执行学校
    handleSaveAddSchoolBtnClick() {
      this.isSavingSchooldList = true;
      // 填入表格数据
      this.fillSchoolFormItem();
      this.addSchoolDialogVisible = false;
    },
    // 添加仪器耗材
    handleAddMaterialBtnClick() {
      this.addSchoolOrMaterial = 0;
      this.fetchMaterialListInfo();
      // 保存数据
      this.tempAddMaterialList = [];
      for (let i = 0; i < this.addMaterialList.length; i++) {
        let obj = {};
        for (const key in this.addMaterialList[i]) {
          obj[key] = this.addMaterialList[i][key];
        }
        this.tempAddMaterialList.push(obj);
      }
      // 如果表格中的equipRequire改变,需要带给 this.addMaterialList
      if (this.addedMaterialDataList.length > 0) {
        this.addedMaterialDataList.forEach((it) => {
          this.addMaterialList.forEach((item) => {
            if (it.materialId === item.materialId) {
              item.equipRequire = it.equipRequire;
            }
          });
        });
      }
      this.addSchoolDialogVisible = true;
    },
    // 删除所有已添加耗材按钮
    handleRemoveAllAddedMaterialBtnClick() {
      this.addMaterialList = [];
      this.materialTableDataList.forEach((it) => {
        it.isSelected = false;
        this.$refs.materialTableDataList.toggleRowSelection(it, false);
      });
    },
    // 删除已添加耗材按钮
    handleRemoveSelectMaterialBtn(item) {
      this.addMaterialList.forEach((it, i) => {
        if (item.materialId == it.materialId) {
          this.addMaterialList.splice(i, 1);
        }
      });
      this.$nextTick(() => {
        this.materialTableDataList.forEach((it) => {
          if (it.materialId == item.materialId) {
            this.$refs.materialTableDataList.toggleRowSelection(it, false);
            it.isSelected = false;
          }
        });
      });
    },
    // 表格内添加仪器耗材按钮
    handleTableAddMaterialBtnClick(row) {
      this.addMaterialList.push(row);
    },
    // 取消添加仪器耗材
    handleCancelAddMaterialBtnClick() {
      this.addMaterialList = this.tempAddMaterialList;
      this.addSchoolDialogVisible = false;
    },
    // 保存添加仪器耗材
    handleSaveAddMaterialBtnClick() {
      this.isSavingMaterialList = true;
      // 填充表格
      // console.log(this.addMaterialList);
      this.addedMaterialDataList = [
        ...this.addMaterialList.map((item) => ({
          ...item,
          equipRequire: item.equipRequire ? item.equipRequire : 1,
        })),
      ];
      this.addSchoolDialogVisible = false;
    },
    // 垃圾桶icon点击事件
    handleDeleteAddedMaterialBtnClick(row) {
      // console.log(row)
      for (let i = 0; i < this.addedMaterialDataList.length; i++) {
        if (
          this.addedMaterialDataList[i].internationCode === row.internationCode
        ) {
          this.addedMaterialDataList.splice(i, 1);
          i--;
        }
      }
      for (let i = 0; i < this.addMaterialList.length; i++) {
        if (this.addMaterialList[i].internationCode === row.internationCode) {
          this.addMaterialList.splice(i, 1);
          i--;
        }
      }
    },
    // 保存并发布，暂存标准按钮--通过 saveType 判断保存还是暂存
    handleSaveAndPublishStandardBtnClick(saveType) {
      console.log(this.addSchoolList, "***");
      this.$refs.equipStandardFormData.validate((valid) => {
        if (valid) {
          if (this.equipStandardFormData.subject === "") {
            this.$message.error("请选择所属科目!");
            return;
          }
          if (this.equipStandardFormData.period === "") {
            this.$message.error("请选择所属学段!");
            return;
          }
          if (this.addSchoolList.length === 0) {
            this.$message.error("请添加执行学校!");
            return;
          }
          if (this.addedMaterialDataList.length === 0) {
            this.$message.error("请添加仪器耗材!");
            return;
          }
          if (this.addOrEditEquipStandard === 1) {
            // 这里进行添加操作
            let addEquipMaterialRelations = this.addedMaterialDataList.map(
              (item) => ({
                consumeMaterialId: item.materialId,
                equipRequire: item.equipRequire,
                minStandardNum: item.materialMinStandardNum,
                maxStandardNum: item.materialMaxStandardNum,
              })
            );
            let addExecuteSchoolIds = this.addSchoolList?.map(
              (item) => item.sysOrgSchoolId
            );
            let addEquipStandardParams = {
              addEquipMaterialRelations,
              equipStandardCode: this.equipStandardFormData.standardCode,
              equipStandardName: this.equipStandardFormData.standardName,
              periodDicId: this.equipStandardFormData.period,
              subjectDicId: this.equipStandardFormData.subject,
              enabled: 1,
              addExecuteSchoolIds,
            };
            addEquipStandardInfoApi(saveType, addEquipStandardParams).then(
              (res) => {
                if (res.success) {
                  this.$message.success("保存成功!");
                  this.fetchEquipStandardListInfo();
                  this.addOrEditEquipStandardDialogVisible = false;
                } else {
                  this.$message.error(res.msg);
                  return;
                }
              }
            );
            // console.log(addEquipStandardParams);
          } else {
            // 这里进行编辑操作
            let delEquipMaterialRelationIds = [];
            let addEquipMaterialRelations = [];
            let editEquipMaterialRelations = [];
            let addExecuteSchoolIds = [];
            let tempDelExecuteSchoolIds = [];
            let delExecuteSchoolIds = [];
            let enabled = saveType === "save" ? 1 : 0;
            // 添加需要删除的耗材id
            for (
              let i = 0;
              i < this.addedMaterialDataListBeforeEdit.length;
              i++
            ) {
              let result = true;
              for (let j = 0; j < this.addedMaterialDataList.length; j++) {
                if (
                  this.addedMaterialDataListBeforeEdit[i].materialId ===
                  this.addedMaterialDataList[j].materialId
                ) {
                  result = false;
                  editEquipMaterialRelations.push({
                    equipMaterialRelationId:
                      this.addedMaterialDataListBeforeEdit[i]
                        .equipMaterialRelationId,
                    minStandardNum:
                      this.addedMaterialDataListBeforeEdit[i]
                        .materialMinStandardNum,
                    maxStandardNum:
                      this.addedMaterialDataListBeforeEdit[i]
                        .materialMaxStandardNum,
                    equipRequire: this.addedMaterialDataList[j].equipRequire,
                  });
                  break;
                }
              }
              if (result) {
                delEquipMaterialRelationIds.push(
                  this.addedMaterialDataListBeforeEdit[i]
                    .equipMaterialRelationId
                );
              }
            }
            // 添加需要添加的耗材id
            for (let i = 0; i < this.addedMaterialDataList.length; i++) {
              let result = true;
              for (
                let j = 0;
                j < this.addedMaterialDataListBeforeEdit.length;
                j++
              ) {
                if (
                  this.addedMaterialDataList[i].materialId ===
                  this.addedMaterialDataListBeforeEdit[j].materialId
                ) {
                  result = false;
                  break;
                }
              }
              if (result) {
                addEquipMaterialRelations.push({
                  consumeMaterialId: this.addedMaterialDataList[i].materialId,
                  equipRequire: this.addedMaterialDataList[i].equipRequire,
                  minStandardNum:
                    this.addedMaterialDataList[i].materialMinStandardNum,
                  maxStandardNum:
                    this.addedMaterialDataList[i].materialMaxStandardNum,
                });
              }
            }
            // addSchoolList: [],
            // addSchoolListBeforEdit: [], // 保存之前的数据，用于比较后发送更新请求
            // 添加需要删除的学校
            for (let i = 0; i < this.addSchoolListBeforEdit.length; i++) {
              let result = true;
              for (let j = 0; j < this.addSchoolList.length; j++) {
                if (
                  this.addSchoolListBeforEdit[i].sysOrgSchoolId ===
                  this.addSchoolList[j].sysOrgSchoolId
                ) {
                  result = false;
                  break;
                }
              }
              if (result) {
                tempDelExecuteSchoolIds.push(
                  this.addSchoolListBeforEdit[i].sysOrgSchoolId
                );
              }
            }
            // 将tempDelExecuteSchoolIds中的id对应的relationId提取出
            // console.log(this.schoolDataRelationList, "1111");
            // console.log(tempDelExecuteSchoolIds, "222");
            this.schoolDataRelationList?.forEach((item) => {
              tempDelExecuteSchoolIds.forEach((it) => {
                if (item.sysOrgSchoolDTO.sysOrgSchoolId == it) {
                  delExecuteSchoolIds.push(item.relationId);
                }
              });
            });

            // 添加需要添加的学校
            for (let i = 0; i < this.addSchoolList.length; i++) {
              let result = true;
              for (let j = 0; j < this.addSchoolListBeforEdit.length; j++) {
                if (
                  this.addSchoolListBeforEdit[j].sysOrgSchoolId ===
                  this.addSchoolList[i].sysOrgSchoolId
                ) {
                  result = false;
                  break;
                }
              }
              if (result) {
                addExecuteSchoolIds.push(this.addSchoolList[i].sysOrgSchoolId);
              }
            }

            let editEquipStandardParams = {
              addEquipMaterialRelations,
              delEquipMaterialRelationIds,
              editEquipMaterialRelations,
              enabled,
              equipStandardId: this.equipStandardId,
              equipStandardCode: this.equipStandardFormData.standardCode,
              equipStandardName: this.equipStandardFormData.standardName,
              periodDicId: this.equipStandardFormData.period,
              subjectDicId: this.equipStandardFormData.subject,
              addExecuteSchoolIds,
              delExecuteSchoolIds,
            };
            updateEquipStandardInfoApi(editEquipStandardParams).then((res) => {
              if (res.success) {
                this.$message.success("编辑成功!");
                this.fetchEquipStandardListInfo();
                this.addOrEditEquipStandardDialogVisible = false;
              } else {
                this.$message.error(res.msg);
                return;
              }
            });
          }
        }
      });
    },
    // 取消标准按钮
    handleCancelStandardBtnClick() {
      this.addOrEditEquipStandardDialogVisible = false;
    },
    // 新增配备标准
    handleAddEquipStandard() {
      this.addOrEditEquipStandard = 1;
      this.equipStandardFormData = {
        standardName: "",
        standardCode: "",
        subject: "",
        period: "",
        schoolList: "",
      };
      this.addedMaterialDataList = [];
      this.addMaterialList = [];
      this.addSchoolList = [];
      this.addOrEditEquipStandardDialogVisible = true;
      this.$nextTick(() => {
        this.$refs.equipStandardFormData.clearValidate();
      });
    },
    // 编辑配备标准
    handleEditEquipStandardBtnClick(row, statusVal) {
      getEquipStandardDetailApi(row.equipStandardId).then((res) => {
        if (res.success) {
          const { data } = res;
          console.log(data);
          this.addOrEditEquipStandard = statusVal;
          this.equipStandardFormData = {
            standardName: data.equipStandardName,
            standardCode: data.equipStandardCode,
            subject: data.subjectDicId,
            period: data.periodDicId,
            schoolList: "",
          };
          if (data.equipMaterialRelationDTOList) {
            this.addedMaterialDataList = [...data.equipMaterialRelationDTOList];
            this.addMaterialList = [...data.equipMaterialRelationDTOList];
            this.addedMaterialDataListBeforeEdit = [
              ...data.equipMaterialRelationDTOList,
            ];
          }
          // 填充执行学校数据
          if (data.equipSchoolRelationDTOList) {
            //       addSchoolList: [],
            // tempAddSchoolList: [], 保存之前的数据，用于取消弹框后恢复数据
            // addSchoolListBeforEdit:[], 保存之前的数据，用于比较后发送更新请求
            this.addSchoolList = data.equipSchoolRelationDTOList.map(
              (item) => item.sysOrgSchoolDTO
            );
            this.tempAddSchoolList = data.equipSchoolRelationDTOList.map(
              (item) => item.sysOrgSchoolDTO
            );
            this.addSchoolListBeforEdit = data.equipSchoolRelationDTOList.map(
              (item) => item.sysOrgSchoolDTO
            );
            this.schoolDataRelationList = data.equipSchoolRelationDTOList;
            this.fillSchoolFormItem();
          }
          this.equipStandardId = data.equipStandardId;
          this.addOrEditEquipStandardDialogVisible = true;
        } else {
          this.$message.error(res.msg);
          return;
        }
      });
    },
    // 点击标准名称查看信息
    handleEquipStandardNameClick(row) {
      this.isCheckingEquipStandard = true;
      this.handleEditEquipStandardBtnClick(row, 2);
    },
    // 启用、停用、删除标准
    handleOpenChangeEquipStandardDialog(val, id) {
      this.$confirm(`确定${val}该配备标准吗？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (val === "删除") {
            // 进行删除操作
            deleteEquipStandardInfoApi(id).then((res) => {
              if (res.success) {
                this.fetchEquipStandardListInfo();
                this.$message({
                  type: "success",
                  message: `${val}成功!`,
                });
              } else {
                this.$message.error(res.msg);
                return;
              }
            });
          } else {
            let params = {
              equipStandardId: id,
              enabled: val === "启用" ? 1 : 0,
            };
            // 进行编辑操作
            updateEquipStandardInfoApi(params).then((res) => {
              if (res.success) {
                this.$message({
                  type: "success",
                  message: `${val}成功!`,
                });
                this.fetchEquipStandardListInfo();
              } else {
                this.$message.error(res.msg);
                return;
              }
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: `已取消${val}`,
          });
        });
    },
    // 调用该方法可以判断学校表格一栏是显示 添加 还是 已添加
    handleSchoolStatusCheck(row) {
      let result = false;
      if (this.addSchoolList) {
        this.addSchoolList.forEach((item) => {
          if (item.sysOrgSchoolId === row.sysOrgSchoolId) {
            result = true;
          }
        });
      }
      return result;
    },
    // 调用该方法可以判断仪器耗材表格一栏是显示 添加 还是 已添加
    handleMaterialStatusCheck(row) {
      let result = false;
      if (this.addMaterialList) {
        this.addMaterialList.forEach((item) => {
          if (item.internationCode === row.internationCode) {
            result = true;
          }
        });
      }
      return result;
    },
    // 表格选择事件
    handleSelectionChange(selection) {
      this.schoolSelectionList = selection;
    },
    // 批量添加学校事件
    handleBatchAddBtnClick() {
      if (this.schoolSelectionList.length === 0) return;
      // console.log(this.schoolSelectionList, this.addSchoolList)
      for (let i = 0; i < this.schoolSelectionList.length; i++) {
        let result = true;
        for (let j = 0; j < this.addSchoolList.length; j++) {
          if (
            this.schoolSelectionList[i].sysOrgSchoolId ===
            this.addSchoolList[j].sysOrgSchoolId
          ) {
            result = false;
          }
        }
        if (result) {
          this.addSchoolList.push(this.schoolSelectionList[i]);
        }
      }
      // 清空已选的选框
      this.$refs.schoolTableDataList.clearSelection();
    },
    // 回填表单中执行学校一栏数据
    fillSchoolFormItem() {
      this.equipStandardFormData.schoolList = "";
      this.addSchoolList?.forEach((item) => {
        if (!this.equipStandardFormData.schoolList) {
          this.equipStandardFormData.schoolList += item.name;
        } else {
          this.equipStandardFormData.schoolList += "，" + item.name;
        }
      });
    },
    handleSelectOneMaterial(selection, row) {
      this.materialRowClick(row);
    },
    handleSelectAllMaterial(selection) {
      if (selection.length > 0) {
        selection.forEach((it) => {
          let result = true;
          this.addMaterialList.forEach((it2) => {
            if (it.materialId == it2.materialId) {
              result = false;
            }
          });
          if (result) {
            it.isSelected = true;
            this.addMaterialList.push(it);
          }
        });
      } else {
        this.materialTableDataList.forEach((it) => {
          this.addMaterialList.forEach((it2) => {
            if (it.materialId == it2.materialId) {
              this.$nextTick(() => {
                this.handleRemoveSelectMaterialBtn(it2);
              });
            }
          });
        });
      }
    },
    materialRowClick(row, column, event) {
      if (row.isSelected) {
        this.handleRemoveSelectMaterialBtn(row);
      } else {
        this.$refs.materialTableDataList.toggleRowSelection(row, true);
        row.isSelected = true;
        this.addMaterialList.push(row);
      }
    },
    // 重置配备标准按钮
    handleResetEquipStandardBtnClick() {
      this.equipStandardFormCondition = {
        equipStandardCode: "",
        equipStandardName: "",
        subjectDicId: "",
        periodDicId: "",
        enabled: "",
      };
      this.fetchEquipStandardListInfo();
    },
    // 查询配备标准按钮
    handleQueryEquipStandardBtnClick() {
      this.equipStandardPageInfo.pageIndex = 1;
      this.equipStandardPageInfo.pageSize = 10;
      this.fetchEquipStandardListInfo();
    },
    // 修改页容量
    handleSizeChangeEquipStandardPageInfo(val) {
      this.equipStandardPageInfo.pageIndex = 1;
      this.equipStandardPageInfo.pageSize = val;
      this.fetchEquipStandardListInfo();
    },
    // 修改当前页
    handleCurrentChangeEquipStandardPageInfo(val) {
      this.equipStandardPageInfo.pageIndex = val;
      this.fetchEquipStandardListInfo();
    },
    // 重置耗材表
    handleResetMaterialTableDataBtnClick() {
      this.materialStandardInput = "";
      this.materialTableDataMsgPage.pageIndex = 1;
      this.materialTableDataMsgPage.pageSize = 5;
      this.fetchMaterialListInfo();
    },
    // 查询耗材表
    handleQueryMaterialTableDataBtnClick() {
      // this.materialStandardInput
      let reg = /[\u4e00-\u9fa5]+/g;
      let params = {};
      if (reg.test(this.materialStandardInput)) {
        // console.log("匹配到中文")
        params.materialName = this.materialStandardInput;
      } else {
        // console.log("匹配到编号")
        params.internationCode = this.materialStandardInput;
      }
      this.materialTableDataMsgPage.pageIndex = 1;
      this.materialTableDataMsgPage.pageSize = 5;
      this.fetchMaterialListInfo(params);
    },
    // 修改仪器耗材分页页容量
    handleSizeChangeMaterialTableDataMsgPage(val) {
      this.materialTableDataMsgPage.pageIndex = 1;
      this.materialTableDataMsgPage.pageSize = val;
      this.fetchMaterialListInfo();
    },
    // 修改仪器耗材分页当前页
    handleCurrentChangeMaterialTableDataMsgPage(val) {
      this.materialTableDataMsgPage.pageIndex = val;
      this.fetchMaterialListInfo();
    },
    handleResetSchoolData() {
      this.schoolListQueryCondition = {
        sysOrgAreaId: "",
        name: "",
      };
      this.fetchSchoolInfo();
    },
    // 日期转换
    handleTimeFormate(timestamp) {
      let timer = new Date(timestamp);
      return formatDate(timer, "yyyy-MM-dd hh-mm-ss");
    },
    handleSelectOneSchool(selection, row){
      this.schoollRowClick(row)
    },
    handleSelectAllSchool(selection){
      if (selection.length > 0) {
        selection.forEach((it) => {
          let result = true;
          this.addSchoolList.forEach((it2) => {
            if (it.sysOrgSchoolId == it2.sysOrgSchoolId) {
              result = false;
            }
          });
          if (result) {
            it.isSelected = true;
            this.addSchoolList.push(it);
          }
        });
      } else {
        this.schoolTableDataList.forEach((it) => {
          this.addSchoolList.forEach((it2) => {
            if (it.sysOrgSchoolId == it2.sysOrgSchoolId) {
              this.$nextTick(() => {
                this.handleRemoveSelectSchoolBtn(it2);
              });
            }
          });
        });
      }
    },
    schoollRowClick(row){
      if (row.isSelected) {
        this.handleRemoveSelectSchoolBtn(row);
      } else {
        this.$refs.schoolTableDataList.toggleRowSelection(row, true);
        row.isSelected = true;
        this.addSchoolList.push(row);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.redStar::before {
  content: "*";
  color: #f56c6c;
  margin-right: 4px;
}
::v-deep .addSchoolInput {
  .el-textarea__inner::placeholder {
    text-decoration: underline;
  }
}
.tag-title {
  padding-left: 10px;
  border-left: 1px solid #ccc;
  font-size: 16px;
  height: 16px;
  line-height: 16px;
  margin-bottom: 25px;
}
.tag-content {
  margin-bottom: 25px;
  width: 95%;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 0 15px 10px 15px;
}
.tag-content-item {
  padding: 0 10px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 5px 0 0 5px;
  position: relative;
  display: inline-block;
  margin-right: 40px;
  margin-top: 10px;
}
.tag-item-x {
  position: absolute;
  right: -22px;
  top: -1px;
  height: 35px;
  width: 20px;
  line-height: 35px;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
}
::v-deep .equipStandardFormData {
  .el-form-item__error {
    margin-left: 120px;
  }
}
</style>
